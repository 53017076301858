/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import SectionHeadingBlock from "@src/components/SectionHeadingBlock";
import Container from "@src/components/Container";
import InternalLink from "@src/components/InternalLink";
export default ({ location }) => (
  <Content
    pageTitle="Contact"
    location={location}
  >
    <div>
      <SectionHeadingBlock heading="School, libraries and group sessions">
        <div>
          <Container>
            <ImageTextBlock
              body={
                <div>

                  <p>
                  For all school, library or public speaking bookings and enquiries, please contact Bernard’s agency:
                  </p>

                  <p>
Booked Out Speakers’ Agency<br/>
PO Box 580<br/>
South Yarra VIC 3141<br/>
PH: (03) 9824 0177<br/>
<a href="mailto:bookings@bookedout.com.au">bookings@bookedout.com.au</a>
                  </p>
                </div>
              }
            />
          </Container>
        </div>
      </SectionHeadingBlock>
      <SectionHeadingBlock heading="Comics creation, tutoring or mentoring:">
        <div>
          <Container>
            <ImageTextBlock
              body={
                <div>
<p>Contact Bernard Caleo directly to:</p>


<p>teach you or someone you know about the art and fun of making comics;
give you a helping hand with your own comic book project;
talk about a kamishibai performance;
send him a comic to consider for review on the radio.</p>
<br/>

Electronic Mail:
<br/>
<br/>
<a href="mailto:bernard@cardigancomics.com">bernard@cardigancomics.com</a>


<br/>
<br/>
<br/>
Snail Mail:
<br/>
<br/>
Bernard Caleo<br/>
PO Box 2088<br/>
Ventnor 3922<br/>
Phillip Island<br/> 
Victoria


                </div>
              }
            />
          </Container>
        </div>
      </SectionHeadingBlock>
     
      <Container>
        <InternalLink
          text="About Bernard"
          url="/about"
        />
      </Container>
    </div>
  </Content>
);
