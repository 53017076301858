/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import { Link } from "gatsby";
interface ComponentProps {
  text: string;
  url: string;
}

const InternalLink = ({ text, url }: ComponentProps) => {
  return (
    <Link
      to={url}
      sx={{
        fontSize: rem(40),
      }}
    >
      →{""} {text}
    </Link>
  );
};
export default InternalLink;
